import { ActionTypes } from "../Constants/ActionTypes";

const initialState = {
    APPID: 0,
    OKTAAPPID: 0,
    HAMICON:false,
}

export const appIdReducer = (state=initialState, action) => {
    switch(action.type){
        case ActionTypes.APP_ID: 
            return{
                ...state, APPID: action.payload
            }
        case ActionTypes.OKTAAPPID:
            return{
                ...state, OKTAAPPID: action.payload
            }
        case ActionTypes.EMAILLOGO:
                return{
                    ...state, EMAILLOGO: action.payload
                }
        case ActionTypes.HAM_ICON:
              return{
                ...state, HAMICON: action.payload
              }
        default: 
            return state
    }
}
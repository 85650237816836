import React from 'react';
import { Link } from 'pepsico-ds';
import { useTranslation } from 'react-i18next';



function Footer() {
    const { t } = useTranslation();
    return (
        <div>
            <hr className='custom-hr resetMain' />
            <div className='info-container '>
                <p className='footer-info'> By signing in to this site you agree to be bound by the
                    <Link target="_blank"
                        href="https://sso.mypepsico.com/modernsso/Associate_SSO_ltandc.pdf"
                        iconLeading=""
                        iconTrailing=""
                        label="Link"
                        size="medium"
                        style={{
                            paddingLeft: '0.25rem',
                            paddingRight: '0.25rem'
                        }}
                        useAnchor>Terms of Use, Legal Terms and Conditions</Link> and
                    <Link target="_blank"
                        href="https://sso.mypepsico.com/modernsso/Associate_SSO_Privacy.pdf"
                        iconLeading=""
                        iconTrailing=""
                        label="Link"
                        size="medium"
                        style={{
                            paddingLeft: '0.25rem',
                            paddingRight: '0.25rem'
                        }}
                        useAnchor>Privacy Statement.</Link></p>
                <br />
                © 2024 PepsiCo, Inc. All Rights Reserved
            </div>
        </div>
    )
}

export default Footer;
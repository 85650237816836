import React, { useState } from 'react';
import './settings.css';
import { Icon, Toggle } from 'pepsico-ds';
import { useTheme } from "./themeContext";
import { useMenuContext } from './menuContext';
import ColourPicker from './colour_picker';

const Settings = () => {
    const [isPopoverOpen, setPopoverOpen] = useState(false);
    const { isDarkTheme, toggleTheme } = useTheme();
    const { toggleMenuLayout, menuLayout } = useMenuContext();

    const togglePopover = () => setPopoverOpen(!isPopoverOpen);

    return (
        <div className="settings-container">
            <div className="settings-icon" onClick={togglePopover}>
                <Icon alt="settings" icon="settings" size="medium" />
            </div>
            {isPopoverOpen && (
                <div className="settings-popover">
                    <div className='settingsMenu'>
                        <h3>Settings</h3>
                        <div className='close-icon'>
                            <Icon alt="close" icon="close" size="medium" onClick={() => togglePopover()} />
                        </div>
                    </div>
                    <div className="settings-option">
                        <label>Theme</label>
                        <Toggle
                            label={isDarkTheme ? "Light" : "Dark"}
                            onUpdate={(value) => toggleTheme(value)}
                            size="medium"
                            value=""
                            variant="horizontal"
                        />
                    </div>
                    <div className="settings-option">
                        <ColourPicker />
                    </div>
                    <div className="settings-option">
                        <label>Layout</label>
                        <Toggle
                            label={menuLayout === 'left' ? "Top" : "Left"}
                            onUpdate={(value) => toggleMenuLayout(value === true ? 'left' : 'top')}
                            size="medium"
                            value=""
                            variant="horizontal"
                        />
                        {/* <button onClick={() => handleLayoutChange('left')}>Left Menu</button>
                        <button onClick={() => handleLayoutChange('top')}>Top Menu</button> */}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Settings;

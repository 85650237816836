import React, { useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { Accordion, AccordionItem, Button, ButtonIcon, Card, Font, Icon, ProgressBar, ProgressIndicator, Slot } from 'pepsico-ds';
import './home.css';
import { useTranslation } from 'react-i18next';
import Welcome from './welcome';
// import LanguageDropdown from '../../common/languageDropdown';
import { useNavigate } from 'react-router-dom';
import AgGridComponent from '../../common/ag_grid';

const HomeView2 = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const data = JSON.parse(localStorage.getItem('okta-token-storage'));
    const changePassword = () => {
        navigate('/change-password');
    }
    if (!userName && data?.accessToken?.claims?.testClaim) {
        // console.log(userName)
        // console.log('Data:', data?.accessToken);
        // console.log('Data:', data?.accessToken?.claims);
        // console.log('Data:', data?.accessToken?.claims?.testClaim);
        setUserName(data?.accessToken?.claims?.testClaim);
    }
    const navigateToQuickLinks = (path) => () => {
        navigate(path);
    }
    const rowData = [
        { id: 1, name: 'John Doe', age: 28 },
        { id: 2, name: 'Jane Smith', age: 34 },
        // Add more rows as needed
    ];

    const columnDefs = [
        { headerName: 'ID', field: 'id' },
        { headerName: 'Name', field: 'name' },
        { headerName: 'Age', field: 'age' },
    ];

    return (
        <div id='landingPage' className='landingAlignments'>
            <div className='col-12 pl-0 pr-0' style={{ display: 'grid', gap: '12px' }}>
                <h5 className='ml-3'>Hello {userName}! </h5>
                <div className='d-flex'>
                    <div className='col-9'>
                        <Welcome />
                    </div>
                    <div className='col-3 pl-0 pr-0' id='activity'>
                        <div className="card left-section card-layout">
                            <div className='card-content'>
                                <Accordion alignment="right" onUpdate={function Qa() { }} size="small" state="enable">
                                    <AccordionItem alignment="left" label="User Activity" expanded>
                                        <div className='innerTitleName text-left'>
                                            Tasks Completed
                                        </div>
                                        <div className='d-flex' style={{ gap: '4px' }}>
                                            <span className='innerTitleName'>7/10</span>
                                            <ProgressBar value={12} max={100} />
                                            <span className='innerTitleName'>75%</span>
                                        </div>
                                        <div className='innerTitleName text-left mt-4'>
                                            Approvals
                                        </div>
                                        <div className='d-flex' style={{ gap: '4px' }}>
                                            <span className='innerTitleName'>5/10</span>
                                            <ProgressBar size='medium' value={12} max={100} />
                                            <span className='innerTitleName'>50%</span>
                                        </div>
                                    </AccordionItem>
                                </Accordion>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='col-9' style={{ display: 'grid', gap: '12px' }}>
                        <div className='card'>
                            <div className='card-content'>
                                <Accordion alignment="right" onUpdate={function Qa() { }} size="large" state="enable">
                                    <AccordionItem alignment="left" label={t('quick_links')} expanded="true">

                                        <div className='flex'>
                                            <div className='quickLinks cursor-pointer' onClick={navigateToQuickLinks('/approvals')}>
                                                <div className='approval_logo_background'>

                                                </div>
                                                <div className='quickLinksLabel'><span>{t('View request pending/approved')} </span></div>
                                            </div>
                                            <div className='quickLinks cursor-pointer' onClick={changePassword} >
                                                <div className='password_logo_background'></div>
                                                <div className='quickLinksLabel'><span>Change Password</span></div>
                                            </div>
                                            <div className='quickLinks cursor-pointer'>
                                                <div className='profile_logo_background'></div>
                                                <div className='quickLinksLabel'><span>Profile Update</span></div>
                                            </div>
                                            <div className='quickLinks cursor-pointer'>
                                                <div className='message_logo_background'></div>
                                                <div className='quickLinksLabel'><bold>Message Board</bold></div>
                                            </div>
                                        </div>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                        <div className='flex' style={{ gap: '12px' }}>
                            <div className="card left-section card-layout">
                                <div className='card-content'>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span className='sectionHeader'>Approvals</span>
                                        <Link to='/approvals' className='link'>View all</Link>
                                    </div>
                                    {/* <hr className='custom-hr' /> */}
                                    <Accordion alignment="right" onUpdate={function Qa() { }} size="large" state="enable">
                                        {/* <AccordionItem alignment="right" label="Update alternate email adress "
                                subTitle="Details" title='Update alternate email adress' avatar={{
                                    name: 'Jon Doe'
                                }}
                                badge={{
                                    isCopyable: false,
                                    text: 'Badge'
                                }}>
                                <div className='innerTitleName'>
                                    You can update your alternate email address
                                </div>
                                <div className='innerTitleInfo'>
                                    <div className='titleTime'>
                                        Estimated time 3 mins
                                    </div>
                                    <div className='link' style={{ fontSize: '10px' }}> Get Started</div>
                                </div>
                            </AccordionItem> */}
                                        <hr className='custom-hr' />
                                        {/* <AccordionItem alignment="right" label="Associate PAAS Account"
                                subTitle="Details" title='Associate PAAS Account' avatar={{
                                    name: 'Jon Doe'
                                }}
                                badge={{
                                    isCopyable: false,
                                    text: 'Badge'
                                }}>
                                <div className='innerTitleName'>
                                    You can update your alternate email address
                                </div>
                                <div className='innerTitleInfo'>
                                    <div className='titleTime'>
                                        Estimated time 3 mins
                                    </div>
                                    <div className='link' style={{ fontSize: '10px' }}> Get Started</div>
                                </div>
                            </AccordionItem> */}
                                    </Accordion>
                                    {/* <div style={{ margin: '12px', justifyContent: 'flex-end', display: 'flex' }}>
                            <Button size="medium" text="Approve" />
                        </div> */}
                                    {/* <hr className='custom-hr' /> */}
                                </div>
                            </div>
                            <div className="card left-section card-layout">
                                <div className='card-content'>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span className='sectionHeader'>Tasks List</span>
                                        <Link to='/approvals' className='link'>View all</Link>
                                    </div>
                                    <hr className='custom-hr' />
                                    <Accordion alignment="right" onUpdate={function Qa() { }} size="medium" state="enable">
                                        <AccordionItem alignment="right" label="Update alternate email adress "
                                            subTitle="Details" title='Update alternate email adress' avatar={{
                                                name: 'Jon Doe'
                                            }}
                                            badge={{
                                                isCopyable: false,
                                                text: 'Badge'
                                            }}>
                                            <div className='innerTitleName'>
                                                You can update your alternate email address
                                            </div>
                                            <div className='innerTitleInfo'>
                                                <div className='titleTime'>
                                                    Estimated time 3 mins
                                                </div>
                                                <div className='link' style={{ fontSize: '10px' }}> Get Started</div>
                                            </div>
                                        </AccordionItem>
                                        <hr className='custom-hr' />
                                        <AccordionItem alignment="right" label="Associate PAAS Account"
                                            subTitle="Details" title='Associate PAAS Account' avatar={{
                                                name: 'Jon Doe'
                                            }}
                                            badge={{
                                                isCopyable: false,
                                                text: 'Badge'
                                            }}>
                                            <div className='innerTitleName'>
                                                You can update your alternate email address
                                            </div>
                                            <div className='innerTitleInfo'>
                                                <div className='titleTime'>
                                                    Estimated time 3 mins
                                                </div>
                                                <div className='link' style={{ fontSize: '10px' }}> Get Started</div>
                                            </div>
                                        </AccordionItem>
                                    </Accordion>
                                    <hr className='custom-hr' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-3 pl-0 pr-0' id='activity'>
                        <div className="card card-layout" id='announcements'>
                            <div className='card-content'>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span className='sectionHeader'>IAM Announcements</span>
                                    <ButtonIcon icon="add" label="Label" onClick={function Qa() { }} size="medium" />
                                </div>
                                <Accordion alignment="right" onUpdate={function Qa() { }} size="medium" state="enable">
                                    <AccordionItem alignment="right" label="Announcements title"
                                        subTitle="Lorem ipsum Lorem ipsum Lorem..."
                                        badge={{
                                            isCopyable: false,
                                            text: 'New'
                                        }}>
                                        <div className='innerTitleName'>
                                            You can update your alternate email address
                                        </div>
                                        <div className='innerTitleInfo'>
                                            <div className='titleTime'>
                                                Estimated time 3 mins
                                            </div>
                                            <div className='link' style={{ fontSize: '10px' }}> Get Started</div>
                                        </div>
                                    </AccordionItem>

                                    <AccordionItem alignment="right" label="Announcements title"
                                        subTitle="Lorem ipsum Lorem ipsum Lorem..."
                                        badge={{
                                            isCopyable: false,
                                            text: 'New'
                                        }}>
                                        <div className='innerTitleName'>
                                            You can update your alternate email address
                                        </div>
                                        <div className='innerTitleInfo'>
                                            <div className='titleTime'>
                                                Estimated time 3 mins
                                            </div>
                                            <div className='link' style={{ fontSize: '10px' }}> Get Started</div>
                                        </div>
                                    </AccordionItem>

                                    <AccordionItem alignment="right" label="Announcements title"
                                        subTitle="Lorem ipsum Lorem ipsum Lorem..."
                                        badge={{
                                            isCopyable: false,
                                            text: 'New'
                                        }}>
                                        <div className='innerTitleName'>
                                            You can update your alternate email address
                                        </div>
                                        <div className='innerTitleInfo'>
                                            <div className='titleTime'>
                                                Estimated time 3 mins
                                            </div>
                                            <div className='link' style={{ fontSize: '10px' }}> Get Started</div>
                                        </div>
                                    </AccordionItem>


                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='support'>
                    <div style={{ padding: '8px', textAlign: 'center' }}>Need help? Talk to an expert</div>
                </div>

            </div>
            {/* <div className='col-3 pl-0 pr-0' id='activity'>
                <div className="card left-section card-layout">
                    <div className='card-content'>
                        <Accordion alignment="right" onUpdate={function Qa() { }} size="small" state="enable">
                            <AccordionItem alignment="left" label="User Activity" expanded>
                                <div className='innerTitleName text-left'>
                                    Tasks Completed
                                </div>
                                <div className='d-flex' style={{ gap: '4px' }}>
                                    <span className='innerTitleName'>7/10</span>
                                    <ProgressBar value={12} max={100} />
                                    <span className='innerTitleName'>75%</span>
                                </div>
                                <div className='innerTitleName text-left mt-4'>
                                    Approvals
                                </div>
                                <div className='d-flex' style={{ gap: '4px' }}>
                                    <span className='innerTitleName'>5/10</span>
                                    <ProgressBar size='medium' value={12} max={100} />
                                    <span className='innerTitleName'>50%</span>
                                </div>
                            </AccordionItem>
                        </Accordion>

                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default HomeView2;

import React, { useState } from 'react';
import { Button, Dropdown, TextInput } from "pepsico-ds";
import './auth.css';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Footer from '../common/footer';
import forge from "node-forge";
import CryptoJS from 'crypto-js'
import axios from 'axios';
import CountDownTimer from './count-down-timer';
const publicKey = `-----BEGIN RSA PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsoZnlKyNcmMo3zrdx3KGc+/+fk1Jn7zYVyENg8hf6+SQ+p03Mzx8+hO6QUWgkJtlQfWBabZWMolGcpr37QTs4LJc6IEdvy24b8SjWM8AXj6II+SRpOR1m7W9I0eD16+6/nLWBpB5FAxCjrODC//BZVvGStXnTfQZc+/E2EamK21DneFkJaC9FlTwoNSy+LM5bjf6Q2YjWuX06uePzaAOLnycyhA8fHMoHwBQLdzfpKwRKMOr3VnDilem9RH9zGi44rg8y2g2TY1WzvsStmDM8NduV5aOnwzFMo+BTugfLhdP4ZmYR/BX5WLfPmxkAEI5fVNONBCuzoJsyX424wMLlwIDAQAB
    -----END RSA PUBLIC KEY-----`;
export const encrypt = (plainText, key) => {
    const publicKey = forge.pki.publicKeyFromPem(key);
    return forge.util.encode64(
        publicKey.encrypt(plainText, "RSA-OAEP", {
            md: forge.md.md5.create(),
            mgf1: {
                md: forge.md.sha1.create()
            }
        })
    )
};

export const encryptAES = (msg) => {
    const key = CryptoJS.lib.WordArray.random(8).toString();
    const iv = CryptoJS.lib.WordArray.random(8).toString();

    // encrypt some bytes using GCM mode
    const cipher = forge.cipher.createCipher('AES-GCM', key);
    cipher.start({
        iv: iv,
        additionalData: 'nvn', // optional
        tagLength: 128 // optional, defaults to 128 bits
    });
    cipher.update(forge.util.createBuffer(msg));
    cipher.finish();
    const encrypted = cipher.output;
    const encodedB64 = forge.util.encode64(encrypted.data);
    const tag = cipher.mode.tag;
    const tagB64 = forge.util.encode64(tag.data);
    return {
        key: encrypt(key, publicKey),
        iv: iv,
        tag: tagB64,
        encrypt: encodedB64,
    }
};

const SecurityForm = ({ securityOptions, sendDataToParent }) => {
    console.log('securityOptions');
    const { REACT_APP_API_ENDPOINT } = process.env;
    const { t } = useTranslation();
    const [securityCheck, setSecurityCheck] = useState('');
    const [counter, setCounter] = useState(1);
    const [disableButton, setDisableButton] = useState(false);
    const [inititalTime, setInitialTime] = useState(0);
    let availalbleQuestions = [];
    if (securityOptions?.['secQuestions']?.length > 0) {
        availalbleQuestions = securityOptions?.['secQuestions'];
    }

    const { register, handleSubmit, setError, formState: { errors }, watch } = useForm();
    const onSecurityQstnsSubmit = (e) => {
        verifySecondaryQuestions(e)
    }

    const verifySecondaryQuestions = (formData) => {
        setSecurityCheck('');
        let body = {};
        body[availalbleQuestions[0]] = encrypt(formData['question1'], publicKey);
        body[availalbleQuestions[1]] = encrypt(formData['question2'], publicKey)
        axios.post(REACT_APP_API_ENDPOINT + 'user/verifySecQ', {
            "gpid": securityOptions.gpid,
            "questionAnswer": body
        }).then(response => {
            console.log(response.data);
            if (response.data?.status === 'Failed') {
                setError("question1", {
                    type: "manual",
                    message: response.data?.message,
                });
                setError("question1", {
                    type: "manual",
                    message: response.data?.message,
                });
                sendDataToParent('security', { status: 'Failed' });
                setCounter(counter + 1);
                console.log(counter);
                if (counter === 1) {
                    setDisableButton(true);
                    setInitialTime(10);
                    setTimeout(() => { setDisableButton(false); }, 10000);
                } else if (counter === 2) {
                    setDisableButton(true);
                    setInitialTime(30);

                    setTimeout(() => { setDisableButton(false); }, 30000);
                } else if (counter === 3) {
                    setDisableButton(true);
                    setInitialTime(70);
                    setTimeout(() => { setDisableButton(false); }, 70000);
                } else if (counter === 4) {
                    setDisableButton(true);
                }
            }
            else {
                sendDataToParent('security', {});
            }
        })
            .catch(error => {
                // Handle error (e.g., display error message)
                console.error('Error fetching data:', error?.response?.data?.message);
                setSecurityCheck(error?.response?.data?.message);
                // return { "gpid": "22221108", "secQuestions": ["What was your first pet's name?", "What was your high school mascot?"], "mobile": "+917604877905" }
            });
    }
    const showPrevious = () => {
        sendDataToParent('previous', {});
    }

    return (
        <div>
            <div className='info-container'>
                <p> Please verify your identitiy by answering your security questions.</p>
            </div>
            <hr className='custom-hr' />
            <div>
                <form style={{ display: 'inline-block' }} onSubmit={handleSubmit(onSecurityQstnsSubmit)} >
                    <div>
                        <TextInput type="text" value={this} {...register('question1')} showLabelIcon required label={t(availalbleQuestions[0])} />
                        {/* {errors.question1 && <div className='fieldError'>{errors.question1.message}</div>} */}
                    </div>
                    <div style={{ width: 'fit-content' }}>
                        <TextInput type="text" value={this} {...register('question2')} showLabelIcon required label={t(availalbleQuestions[1])} />
                        {errors.question1 && <div className='fieldError'>{errors.question1.message}</div>}
                    </div>
                    {/* <div  style={{ width: 'fit-content' }}>
                        <TextInput type="text" value={this} {...register('question3')} showLabelIcon required label={t(availalbleQuestions[2])} />
                        {errors.question1 && <span>{errors.question1.message}</span>}
                    </div> */}
                    <br /><br />
                    <div style={{ display: 'flex', gap: '8px' }}>
                        <Button className='full-button' size="medium" text='Previous' onClick={showPrevious} />
                        <Button type="submit" size='medium' className="full-button" disabled={disableButton}>Continue</Button>
                    </div>
                    {disableButton && <div className='fieldError'>Retry will be enabled in <CountDownTimer initialTime={inititalTime} /> seconds</div>}
                    <br /><br />
                </form>
            </div >
            <Footer />

        </div>
    )
};

export default SecurityForm;
import React, { useEffect, useRef, useState } from 'react';
import { TextInput, Button, Checkbox, Toggle } from 'pepsico-ds';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
// import UserValidate from './validate-user';
import OtpForm from './forgot_pwd_otp';
import forge from "node-forge";
import CryptoJS from 'crypto-js'
import SecurityForm from './forgot_pwd_security';
import Footer from '../common/footer';
import HeaderPublic from '../common/header-public';
import { useOktaAuth } from "@okta/okta-react"
import { useNavigate } from "react-router";
import './auth.css';
import Icon from 'react-icons-kit';
// import LanguageDropdown from '../common/languageDropdown';

const publicKey = `-----BEGIN RSA PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsoZnlKyNcmMo3zrdx3KGc+/+fk1Jn7zYVyENg8hf6+SQ+p03Mzx8+hO6QUWgkJtlQfWBabZWMolGcpr37QTs4LJc6IEdvy24b8SjWM8AXj6II+SRpOR1m7W9I0eD16+6/nLWBpB5FAxCjrODC//BZVvGStXnTfQZc+/E2EamK21DneFkJaC9FlTwoNSy+LM5bjf6Q2YjWuX06uePzaAOLnycyhA8fHMoHwBQLdzfpKwRKMOr3VnDilem9RH9zGi44rg8y2g2TY1WzvsStmDM8NduV5aOnwzFMo+BTugfLhdP4ZmYR/BX5WLfPmxkAEI5fVNONBCuzoJsyX424wMLlwIDAQAB
    -----END RSA PUBLIC KEY-----`;
export const encrypt = (plainText, key) => {
  const publicKey = forge.pki.publicKeyFromPem(key);
  return forge.util.encode64(
    publicKey.encrypt(plainText, "RSA-OAEP", {
      md: forge.md.md5.create(),
      mgf1: {
        md: forge.md.sha1.create()
      }
    })
  )
};

export const encryptAES = (msg) => {
  const key = CryptoJS.lib.WordArray.random(8).toString();
  const iv = CryptoJS.lib.WordArray.random(8).toString();

  // encrypt some bytes using GCM mode
  const cipher = forge.cipher.createCipher('AES-GCM', key);
  cipher.start({
    iv: iv,
    additionalData: 'nvn', // optional
    tagLength: 128 // optional, defaults to 128 bits
  });
  cipher.update(forge.util.createBuffer(msg));
  cipher.finish();
  const encrypted = cipher.output;
  const encodedB64 = forge.util.encode64(encrypted.data);
  const tag = cipher.mode.tag;
  const tagB64 = forge.util.encode64(tag.data);
  return {
    key: encrypt(key, publicKey),
    iv: iv,
    tag: tagB64,
    encrypt: encodedB64,
  }
};

function ForgotPassword() {
  const { REACT_APP_API_ENDPOINT, REACT_APP_NAME} = process.env;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const [mobileNumber, setMobileNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailId, setEmailId] = useState('');
  // const [verifyOption, setVerifyOption] = useState('');
  const [isGpidValid, setGpidValid] = useState(false);
  const [isOtpSent, setOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isUserValidate, setUserValidate] = useState(false);
  const [isForgotPwdOptionType, setForgotPwdOptionType] = useState(false);
  const [selectedOtp, setSelectedOtp] = useState(false);
  const [selectedCall, setSelectedCall] = useState(false);
  const [selectedSecurityQuestion, setSelectedSecurityQuestion] = useState(false);
  const [gpidErrorMessage, setGpidErrorMessage] = useState('');
  const [res, setres] = useState({});
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [endpointAccounts, setEndpointAccounts] = useState(['myidM', 'IDX', 'PKI']);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isTyped, setIsTyped] = useState(false);
  const [isBg, setIsBg] = useState(false);
  const [passwordHints, setPasswordHints] = useState({
    minLength: false,
    hasLowercase: false,
    hasUppercase: false,
    hasSpecialChar: false,
    hasTwoNumbers: false,
    noSequentialChars: true,
    noForbiddenKeywords: true,
    noRepeatingChars: true,
    notStartWithNumber: true,
    notEndWithNumber: true,
  });

  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChange = (event) => {
    console.log(event);
    // let resp= getChallengeFactors();
    setErrorMessage('');
    const onlyNumbers = event.target.value.replace(/[^0-9]/g, ''); // Regex to remove non-numeric characters
    setInputValue(onlyNumbers);
  }

  const handleToggle = () => {
    if (type === 'password') {
      setIcon(eye)
      setType('text')
    } else {
      setIcon(eyeOff)
      setType('password')
    }
  }

  const resetTypeSelected = (type) => {
    // setVerifyOption(type);
    verifyUser(type);
    if (type === 'otp') {
      setSelectedOtp(true);
      setSelectedCall(false);
      setSelectedSecurityQuestion(false);
    }
    else if (type === 'call') {
      setSelectedCall(true);
      setSelectedOtp(false);
      setSelectedSecurityQuestion(false);
    }
    else if (type === 'security') {
      setSelectedOtp(false);
      setSelectedSecurityQuestion(true);
    }
  }

  const { register, handleSubmit, setError, clearErrors, formState: { errors, isValid }, watch } = useForm({
    mode: 'onChange',
    criteriaMode: "all"
  });
  const password = watch("password");
  const onConfirmation = (value) => {
    setIsConfirmed(value);
  }
  const passwordValidation = (password) => {
    // Regex pattern for the MUST requirements
    const pattern = /^(?!.*(.)\1{2})(?=.*[a-z])(?=.*[A-Z])(?=.*\d.*\d)(?=.*[!^*()_|~`{}.#\[\]:;'"<>?,])(?=.{8,14}$)(?!^\d)(?!.*\d$)/;

    // Checking the password against the regex pattern
    if (!pattern.test(password)) {
      return "Password does not meet the basic criteria.";
    }

    // Additional checks for MUST NOT conditions
    const forbiddenKeywords = ["spring", "summer", "fall", "winter", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", "PepsiCo", "Pepsi"];

    // Check for forbidden keywords
    for (const keyword of forbiddenKeywords) {
      if (password.toLowerCase().includes(keyword.toLowerCase())) {
        return `Password must not contain forbidden keywords like ${keyword}`;
      }
    }

    // Check for sequential characters (abc, 123, etc.)
    const isSequential = (str) => {
      for (let i = 0; i < str.length - 2; i++) {
        if (str.charCodeAt(i + 1) === str.charCodeAt(i) + 1 && str.charCodeAt(i + 2) === str.charCodeAt(i) + 2) {
          return true;
        }
      }
      return false;
    };

    if (isSequential(password)) {
      return "Password must not contain sequential characters or numbers.";
    }

    return true; // Password is valid
  };

  const getHintColor = (conditionMet) => {
    // If user hasn't typed yet, default to gray, otherwise red or green based on condition
    return isTyped ? (conditionMet ? 'green' : 'red') : '#9e9e9e';
  };

  const passwordMessageValidation = (password) => {
    setIsTyped(true);
    // Base regex for MUST conditions
    const basePattern = /^(?=.*[a-zA-Z]{4,})(?=.*\d{2,})(?=.*[!^*()_|~`{}.#\[\]:;'"<>?,])(?=.{8,14}$)/;

    // Check length, letters, numbers, and special characters
    const isBaseValid = basePattern.test(password);

    // Additional checks
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasSpecialChar = /[!^*()_|~`{}.#\[\]:;'"<>?,]/.test(password);
    const hasTwoNumbers = /\d.*\d/.test(password);

    // Forbidden keywords
    const forbiddenKeywords = ["spring", "summer", "fall", "winter", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December", "PepsiCo", "Pepsi"];
    const noForbiddenKeywords = !forbiddenKeywords.some(keyword => password.toLowerCase().includes(keyword.toLowerCase()));

    // Sequential characters check (abc, 123)
    const isSequential = (str) => {
      for (let i = 0; i < str.length - 2; i++) {
        if (str.charCodeAt(i + 1) === str.charCodeAt(i) + 1 && str.charCodeAt(i + 2) === str.charCodeAt(i) + 2) {
          return true;
        }
      }
      return false;
    };
    const noSequentialChars = !isSequential(password);

    // Repeating characters check (no more than two repeating characters)
    const noRepeatingChars = !/(.)\1{2,}/i.test(password);

    // Check if it starts or ends with a number
    const notStartWithNumber = !/^\d/.test(password);
    const notEndWithNumber = !/\d$/.test(password);

    // Update validation state
    setPasswordHints({
      minLength: isBaseValid,
      hasLowercase: hasLowercase,
      hasUppercase: hasUppercase,
      hasSpecialChar: hasSpecialChar,
      hasTwoNumbers: hasTwoNumbers,
      noSequentialChars: noSequentialChars,
      noForbiddenKeywords: noForbiddenKeywords,
      noRepeatingChars: noRepeatingChars,
      notStartWithNumber: notStartWithNumber,
      notEndWithNumber: notEndWithNumber,
    });
  };


  const onSubmit = (data) => {
    console.log(data);
    const passwordError = passwordValidation(data.password);
    if (passwordError !== true) {
      setError("password", {
        type: "manual",
        message: passwordError
      });
      return;
    }
    updatePassword(data);
  }
  // const { oktaAuth } = useOktaAuth()
  // const history = useNavigate();
  // oktaAuth.isAuthenticated().then(data => {
  //   console.log( data)
  //   if (data) {
  //     history.push('/appconfig');
  //   }
  // });

  const isUserValid = () => {
    //  oktaAuth.signInWithRedirect()
    console.log('test');
    if (inputValue.length === 8) {
      clearErrors("getChalFac");
      getChallengeFactors();
      // setres(getObject(inputValue));
    }
    else {
      setGpidValid(false);
      setUserValidate(false);
      setError("getChalFac", {
        type: "manual",
        message: 'Please enter a valid GPID'
      });
    }
  };

  const getChallengeFactors = () => {
    getEndpointAccounts();
    clearErrors("getChalFac");
    let retried = false;
    axios.post(`${REACT_APP_API_ENDPOINT}user/getChalFactors`, {
      "gpid": inputValue,
    }).then(response => {
      response['data']['gpid'] = inputValue;
      if(response?.data?.status === 'Failed'){
        setGpidErrorMessage(response?.data?.errorMessage);
        setError("getChalFac", {
          type: "manual",
          message: response?.data?.errorMessage
        });
        return;
      }
      if (response?.data?.mobile) {
        setMobileNumber(response?.data?.mobile);
      }
      if (response?.data?.phoneNumber) {
        setPhoneNumber(response?.data?.phoneNumber);
      }
      if (response?.data?.email) {
        setEmailId(response?.data?.email);
      }
      setres(response.data)
      setGpidValid(true);
      setUserValidate(true);
    })
      .then(data => console.log({ data }))
      .catch(error => {
        // Handle error (e.g., display error message)
        const originalConfig = error.config;
        console.error('Error fetching data:', error?.response?.data?.message);
        // setGpidErrorMessage(error?.response?.data?.message);
        if (error?.response?.status === 403 && !retried) {
          retried = true;
          getChallengeFactors();
        }
        else {
          setError("getChalFac", {
            type: "manual",
            message: error?.message
          });
        }
        // return { "gpid": "22221108", "secQuestions": ["What was your first pet's name?", "What was your high school mascot?"], "mobile": "+917604877905" }
      });
  }
  // const getObject = (gpid) => {
  //   return object.filter((obj) => obj.gpid === gpid);
  // }

  // const object = [{
  //   gpid: '09443473',
  //   response: {
  //     question: ['What is favorite car?', 'What is your favorite petname? ', 'What is your favorite color?'],
  //     mobile: ['Mobile phone (SMS/Text): ********3535', 'Mobile phone (SMS/Text): ********0608'],
  //   }
  // },
  // {
  //   gpid: '70707070',
  //   response: {
  //     mobile: ['Mobile phone (SMS/Text): ********3535', 'Mobile phone (SMS/Text): ********0608'],
  //   }
  // },
  // {
  //   gpid: '80808080',
  //   response: {
  //     question: ['What is favorite car?', 'What is your favorite petname? ', 'What is your favorite color?'],
  //   }
  // },
  // {
  //   gpid: '12345678',
  //   response: {
  //     question: ['What is favorite car?', 'What is your favorite petname? '],
  //     mobile: ['Mobile phone (SMS/Text): ********3535',],
  //   }
  // }];

  // const handleClear = () => {
  //   setInputValue('');
  //   setGpidValid(false);
  // };

  const getChildResponse = (type, form) => {
    // console.log(verifyOption);
    setSelectedSecurityQuestion(false);
    if (type === 'security') {
      setSelectedOtp(false);

      // verifySecondaryQuestions(form)
      if (form?.status === 'Failed') {
        setSelectedSecurityQuestion(true);
      }
      else {
        setForgotPwdOptionType(true);
      }
    }
    // if (type === 'otp') {
    //   // sendSmsOtp(form)
    // }
    if (type === 'otp') {
      setSelectedOtp(false);
      // verifyOtpSms(form)
      sendResendSmsOtp('send','OTPSMS')
    }
    if (type === 'resendotpsms') {
      sendResendSmsOtp('resend','OTPSMS')
    }
    if (type === 'call') {
      setSelectedCall(false);
      // verifyOtpSms(form)
      sendResendSmsOtp('send','OTPVOICE')
    }
    if (type === 'resendotpcall') {
      sendResendSmsOtp('send','OTPVOICE',true)
    }
    if (type === 'email') {
      setSelectedOtp(false);
      // verifyOtpEmail(form)
      // setForgotPwdOptionType(true);

    }
    if (type === 'resendotpEmail') {
      sendResendEmailOtp('resend')
    }
    if (type === 'previous') {
      setSelectedOtp(false);
      setSelectedCall(false);
      setSelectedSecurityQuestion(false);
    }

    if (type === 'otpVerified') {
      setSelectedSecurityQuestion(false);
      if (form?.status === 'Failed') {
        setSelectedOtp(true);
      }
      else {
        setSelectedOtp(false);
        setForgotPwdOptionType(true);
      }
    }
    if (type === 'callVerified') {
      setSelectedSecurityQuestion(false);
      if (form?.status === 'Failed') {
        setSelectedCall(true);
      }
      else {
        setSelectedCall(false);
        setForgotPwdOptionType(true);
      }
    }
  }

  const verifyUser = (type) => {
    // console.log(verifyOption);
    if (type === 'security') {
      setSelectedOtp(false);
      setSelectedSecurityQuestion(true);
    }
    if (type === 'otp') {
      sendResendSmsOtp('send','OTPSMS');
      setSelectedSecurityQuestion(false);
      let otpOption = res;
      otpOption.otpType = 'SMS';
      setres(otpOption);
    }
    if (type === 'call') {
      sendResendSmsOtp('send','OTPVOICE');
      setSelectedSecurityQuestion(false);
      let otpOption = res;
      otpOption.otpType = 'CALL';
      setres(otpOption);
    }
    if (type === 'email') {
      sendResendEmailOtp('send');
      let otpOption = res;
      otpOption.otpType = 'Email';
      setres(otpOption);
    }
  }

  const goPrevious = () => {
    setGpidValid(false);
  }
  const setVerificationMethod = () => {
    setForgotPwdOptionType(false);
    setSelectedOtp(false);
    setSelectedSecurityQuestion(false);
    setPasswordSuccess(false);
  }

  // const verifySecondaryQuestions = (formData) => {
  //   setGpidErrorMessage('');
  //   let body = {};
  //   body[formData['questions'][0]] = encrypt(formData.data.question1, publicKey);
  //   body[formData['questions'][1]] = encrypt(formData.data.question2, publicKey)
  //   axios.post('https://myidm.qa.mypepsico.com/portalui/api/myidm/user/verifySecQ', {
  //     "gpid": inputValue,
  //     "questionAnswer": body
  //   }).then(response => response.json())
  //     .then(data => console.log({ data }))
  //     .catch(error => {
  //       // Handle error (e.g., display error message)
  //       console.error('Error fetching data:', error?.response?.data?.message);
  //       setGpidErrorMessage(error?.response?.data?.message);
  //       // return { "gpid": "22221108", "secQuestions": ["What was your first pet's name?", "What was your high school mascot?"], "mobile": "+917604877905" }
  //     });
  // }

  // const sendSmsOtp = (formData) => {
  //   setGpidErrorMessage('');
  //   axios.post('https://myidm.qa.mypepsico.com/portalui/api/myidm/sendOTPSMS', {
  //     "gpid": inputValue
  //   }).then(response => setres(response.data))
  //     .then(data => console.log({ data }))
  //     .catch(error => {
  //       // Handle error (e.g., display error message)
  //       console.error('Error fetching data:', error?.response?.data?.message);
  //       setGpidErrorMessage(error?.response?.data?.message);
  //       // return { "gpid": "22221108", "secQuestions": ["What was your first pet's name?", "What was your high school mascot?"], "mobile": "+917604877905" }
  //     });
  // }

  // const verifyOtpSms = (formData) => {
  //   setGpidErrorMessage('');
  //   axios.post('https://myidm.qa.mypepsico.com/portalui/api/myidm/verifyOTPSMS', {
  //     "gpid": inputValue,
  //     "otp": "23802418",
  //   }).then(response => response.json())
  //     .then(data => console.log({ data }))
  //     .catch(error => {
  //       // Handle error (e.g., display error message)
  //       console.error('Error fetching data:', error?.response?.data?.message);
  //       setGpidErrorMessage(error?.response?.data?.message);
  //       return { "gpid": "22221108", "secQuestions": ["What was your first pet's name?", "What was your high school mascot?"], "mobile": "+917604877905" }
  //     });
  // }

  // const verifySecondaryQuestions = (formData) => {
  //   setGpidErrorMessage('');
  //   let body = {};
  //   body[formData['questions'][0]] = encrypt(formData.data.question1, publicKey);
  //   body[formData['questions'][1]] = encrypt(formData.data.question2, publicKey)
  //   axios.post('https://myidm.qa.mypepsico.com/portalui/api/myidm/user/verifySecQ', {
  //     "gpid": inputValue,
  //     "questionAnswer": body
  //   }).then(response => {
  //     if (response?.data) {
  //       let verifyResponse = response.data;
  //       if (verifyResponse?.status === 'Success' && verifyResponse?.message === '') {
  //         setForgotPwdOptionType(true);
  //       }
  //     }
  //   })
  //     .then(data => console.log({ data }))
  //     .catch(error => {
  //       // Handle error (e.g., display error message)
  //       console.error('Error fetching data:', error?.response?.data?.message);
  //       setGpidErrorMessage(error?.response?.data?.message);
  //       // return { "gpid": "22221108", "secQuestions": ["What was your first pet's name?", "What was your high school mascot?"], "mobile": "+917604877905" }
  //     });
  // }

  const sendResendSmsOtp = (sendResend,smsCall,resendFlag) => {
    setGpidErrorMessage('');
    let otpRequest = resendFlag?{ "gpid": inputValue,"resendOTP": true }:{"gpid": inputValue};
    axios.post(REACT_APP_API_ENDPOINT + `${sendResend}${smsCall}`, otpRequest).then(response => {
      console.log('response', response.data.responseStatus);
      console.log('response', response.data.responseMessage);

      if (response?.data) {
        let sendOtpRes = response.data;
        if (sendOtpRes.responseStatus === 'Success' && sendOtpRes.responseMessage === 'OTP Sent successfully') {
          setSelectedOtp(true);
          setOtpSent(true);
        }
        else if (sendOtpRes.responseStatus === 'Failed') {
          // Handle this with a message 
          console.log('sendOtpRes.responseStatus', sendOtpRes.responseStatus);
          setErrorMessage(sendOtpRes.responseMessage);
          setSelectedOtp(false);
          setOtpSent(false);
        }
      }
    })
      .then(data => console.log({ data }))
      .catch(error => {
        // Handle error (e.g., display error message)
        console.error('Error fetching data:', error?.response?.data?.message);
        setGpidErrorMessage(error?.response?.data?.message);
        // setErrorMessage( error?.responseMessage);

        // return { "gpid": "22221108", "secQuestions": ["What was your first pet's name?", "What was your high school mascot?"], "mobile": "+917604877905" }
      });
  }

  const getEndpointAccounts = () => {
    console.log('Build Environment', REACT_APP_NAME);

    // setGpidErrorMessage('');
    axios.post(REACT_APP_API_ENDPOINT + 'ED9394AC1FD543DAB04DC42D2934C1F5', {
      "gpid": inputValue,
    })
      // .then(response => response.json())
      .then(data => {
        setEndpointAccounts(data?.data?.endpointAccounts)
        console.log(data?.data?.endpointAccounts)
      })
      .catch(error => {
        // Handle error (e.g., display error message)
        console.error('Error fetching data:', error);
        setGpidErrorMessage(error?.response?.data?.message);
        return { "gpid": "22221108", "secQuestions": ["What was your first pet's name?", "What was your high school mascot?"], "mobile": "+917604877905" }
      });
  }
  // const verifyOtpSms = (formData) => {
  //   setGpidErrorMessage('');
  //   axios.post('https://myidm.qa.mypepsico.com/portalui/api/myidm/verifyOTPSMS', {
  //     "gpid": inputValue,
  //     "otp": "23802418",
  //   }).then(response => response.json())
  //     .then(data => console.log({ data }))
  //     .catch(error => {
  //       // Handle error (e.g., display error message)
  //       console.error('Error fetching data:', error?.response?.data?.message);
  //       setGpidErrorMessage(error?.response?.data?.message);
  //       return { "gpid": "22221108", "secQuestions": ["What was your first pet's name?", "What was your high school mascot?"], "mobile": "+917604877905" }
  //     });
  // }

  const sendResendEmailOtp = (sendResend) => {
    setGpidErrorMessage('');
    axios.post(REACT_APP_API_ENDPOINT + `${sendResend}OTPEmail`, {
      "gpid": inputValue
    }).then(response => {
      if (response?.data) {
        let sendOtpEmail = response.data;
        if (sendOtpEmail.status === 'Success' && sendOtpEmail.message === 'Email Sent Successfully') {
          setSelectedOtp(true);
          setOtpSent(true);
        }
        else if (sendOtpEmail.status === 'Failed' && sendOtpEmail.message === 'Not allowed to send SMS now, please try again after 10 minutes') {
          // Handle this with a message 
          setSelectedOtp(false);
          setOtpSent(false);
        }
      }
    })
      .then(data => console.log({ data }))
      .catch(error => {
        // Handle error (e.g., display error message)
        console.error('Error fetching data:', error?.response?.data?.message);
        setGpidErrorMessage(error?.response?.data?.message);
        // return { "gpid": "22221108", "secQuestions": ["What was your first pet's name?", "What was your high school mascot?"], "mobile": "+917604877905" }
      });
  }

  const updatePassword = (data) => {
    setGpidErrorMessage('');
    setForgotPwdOptionType(false);
    setSelectedOtp(false);
    setSelectedSecurityQuestion(false);
    setPasswordSuccess(true);
    axios.put(REACT_APP_API_ENDPOINT + 'user/pupdate', {
      "gpid": inputValue,
      "password": encrypt(data.password, publicKey)
    }).then(response => response.json())
      .then(data => console.log({ data }))
      .catch(error => {
        // Handle error (e.g., display error message)
        console.error('Error fetching data:', error?.response?.data?.message);
        setGpidErrorMessage(error?.response?.data?.message);
        return { "gpid": "22221108", "secQuestions": ["What was your first pet's name?", "What was your high school mascot?"], "mobile": "+917604877905" }
      });
  }

  const navigate = useNavigate(); // Initialize the navigation hook

  const handleLoginClick = () => {
    navigate('/login'); // Redirect to /login
  };

  const setForgotBg = (value) => {
    if (value === true) {
      setIsBg(true);
    }
    else {
      setIsBg(false);
    }
  }

  return (
    <div id='forgot_password_container'>
      {/* <HeaderView /> */}
      {/* <div className='alignToggle'>
        <Toggle
          label={isBg ? "With Out Background Image" : "With Background Image"}
          onUpdate={(value) => { setForgotBg(value) }}
          size="medium"
          value=""
          variant="horizontal"
        />
      </div> */}
      <div className={isBg ? 'pep_forgot_logo_background' : ''}></div>
      {((!selectedOtp && !selectedSecurityQuestion && !isGpidValid) || (isGpidValid && !isUserValidate)) && (
        <div className="card card-layout">
          <div className='idm_logo_background'></div>
          <div>
            <h4>{t('forgot_password_reset')}</h4>

          </div>
          <div className='info-container'>
            <p className='secondary-text'> Please enter the following to identify yourself</p>
          </div>
          <hr className='custom-hr' />

          <div className='w-1-2'>
            <TextInput type="number" required showLabelIcon label={t('label_user_id')} value={inputValue} onChange={handleChange} />
            {errors.getChalFac && <div className='fieldError'>{errors.getChalFac.message}</div>}
            <br /><br />
            <Button className='full-button' size="medium" text='Get Started' onClick={isUserValid} />
          </div>
          <Footer />
        </div>
      )}

      {isGpidValid && !selectedOtp && !selectedSecurityQuestion && !isForgotPwdOptionType && !passwordSuccess && isUserValidate && (
        <div className="card card-layout reg-card">
          <div className='selectionCard'>
            <div style={{ display: 'flex' }}>
              {/* <Icon alt="arrow_back" icon="arrow_back" size="medium" /> */}
              <Button size="small" text="Back" iconLeading='arrow_back' variant="tertiary" onClick={goPrevious} />
            </div>
            <div className='pep_logo_background'></div>
            <div className='password_name'>
              Forgot Password Reset
            </div>

            <div>
              <div className='secondary-text'>USER ID <span className='secondary-text font-700' >{inputValue}</span>
              </div>
            </div>
            <div className='selectionInfo'>
              <div className='secondary-text' >Verification Method</div>
              {/* {verificationTypes.map((type, index) => ( */}
              <div className='selectionItem' onClick={() => resetTypeSelected('otp')}>
                <div className='selectionSmsIcon'></div>
                <div>{'Text ' + mobileNumber.substring(0, 3) + "*****" + mobileNumber.substring(mobileNumber.length - 3)}</div>
              </div>
              <div className='selectionItem' onClick={() => resetTypeSelected('call')}>
                <div className='selectionCallIcon'></div>
                <div>{'Call ' + phoneNumber.substring(0, 3) + "*****" + phoneNumber.substring(phoneNumber.length - 3)}</div>
              </div>
              <div className='selectionItem' onClick={() => resetTypeSelected('email')}>
                <div className='selectionEmailIcon'></div>
                <div>{'Email ' + emailId.substring(0, 3) + "********" + emailId.substring(emailId.length - 11)} </div>
              </div>
              <div className='selectionItem' onClick={() => resetTypeSelected('security')}>
                <div className='selectionSecurityIcon'></div>
                <div>Security Questions</div>
              </div>
              {/* ))} */}
            </div>
            {/* <div className='info-container'>
              <p className='secondary-text'> Please select one of the following options to verify your identity</p>
            </div> */}

            {/* <div style={{ marginTop: '12px' }}> */}
            {/* <Dropdown
                childList={[
                  {
                    displayText: 'Security Questions',
                    id: 'security',
                    isBadge: false
                  },
                  {
                    displayText: 'Text ' + mobileNumber.substring(0, 3) + "*****" + mobileNumber.substring(mobileNumber.length - 3),
                    id: 'otp',
                    isBadge: false
                  }, {
                    displayText: 'Call ' + mobileNumber.substring(0, 3) + "*****" + mobileNumber.substring(mobileNumber.length - 3),
                    id: 'otps',
                    isBadge: false
                  },
                  {
                    displayText: 'Email ' + emailId.substring(0, 3) + "********" + emailId.substring(emailId.length - 11),
                    id: 'email',
                    isBadge: false
                  },
                ]}
                dropdownHeader="Select Verification Method"
                helperText=""
                isRequired
                label="Verification Type"
                selection="single"
                onKeyDown={(e) => e.preventDefault()}
                setSelectedValue={(val) => setVerifyOption(val?.[0]?.id)}
                size="medium"
              /> */}

            {/* <div className='card pwdCard' onClick={() => resetTypeSelected('otp')}>
                <div className='sms_background'></div>
                <div>Via email/sms code</div>
              </div>
              <div className='card pwdCard' onClick={() => resetTypeSelected('security')}>
                <div className='security_background'></div>
                <div>Via security questions</div>
              </div> */}
            {/* </div> */}
            {/* <UserValidate dataParentToChild={res} dataChildToParent={getChildResponse} /> */}
          </div>
          {errorMessage && <div className='fieldError'>{errorMessage}</div>}

          {/* <br /><br />
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button className='full-button' size="medium" text='Previous' onClick={goPrevious} />
            <Button className='full-button' size="medium" text='Continue' onClick={verifyUser} />
          </div>
          <br /><br /> */}

          <Footer />

        </div>
      )}
      {isGpidValid && selectedOtp && !selectedSecurityQuestion && isUserValidate && (
        <div className="card card-layout reg-card">
          <div className='card-content'>
            <div className='idm_logo_background'></div>
            <OtpForm otpOptions={res} sendDataToParent={getChildResponse} />
          </div>
          <Footer />

        </div>
      )}
      {isGpidValid && selectedCall && !selectedSecurityQuestion && isUserValidate && (
        <div className="card card-layout reg-card">
          <div className='card-content'>
            <div className='idm_logo_background'></div>
            <OtpForm otpOptions={res} sendDataToParent={getChildResponse} />
          </div>
          <Footer />

        </div>
      )}
      {isGpidValid && selectedSecurityQuestion && !selectedOtp && isUserValidate && (
        <div className="card card-layout reg-card">
          <div className='card-content'>
            <div className='idm_logo_background'></div>
            <div>
              <h1>Password Reset</h1>
            </div>

            <SecurityForm securityOptions={res} sendDataToParent={getChildResponse} />
          </div>
        </div>
      )}
      {isForgotPwdOptionType && (
        <div className="card card-layout reg-card">
          <div className='card-content'>
            <div className='idm_logo_background'></div>
            <div>
              <h1>Password Reset</h1>
            </div>
            <div className='info-container'>
              <p> You're all set! Please select your new password</p>
            </div>
            <hr className='custom-hr' />
            <form onSubmit={handleSubmit(onSubmit)} >
              <div >
                <TextInput type="text" readOnly value={inputValue} {...register('gpid')} showLabelIcon required label={t('GPID')} />
              </div>
              <div >
                <div style={{ display: 'flex' }}>
                  <TextInput type={type} value={this} {...register('password', { required: 'Password is required' })} showLabelIcon required label={t('New Password')} style={{ width: '-webkit-fill-available' }}
                    onChange={(e) => passwordMessageValidation(e.target.value)} />
                  <div className="flex justify-around items-center " id='hide-icon' onClick={handleToggle}>
                    <Icon className="visible" icon={icon} size={16} onClick={handleToggle} />
                  </div>
                </div>
                {errors.password && <div className='fieldError'>{errors.password.message}</div>}
                <div className='passGuides'>
                  <h5>Password Guidelines</h5>
                  <h5>Must:</h5>
                  <li className='hintText' style={{ color: getHintColor(passwordHints.minLength || passwordHints.hasTwoNumbers) }}>Have at least eight characters ( maximum 14 characters), including two numbers and four letters.</li>
                  <li className='hintText' style={{ color: getHintColor(passwordHints.hasLowercase) }}>Have at least one lowercase letter( a-z)</li>
                  <li className='hintText' style={{ color: getHintColor(passwordHints.hasUppercase) }}>Have at least one uppercase letter( A-Z)</li>
                  <li className='hintText' style={{ color: getHintColor(passwordHints.hasSpecialChar) }}>Contains at least one special character from any of the following values : !^*()_|~`{ }.#[]:";'&lt;	&gt;?,</li>
                  <h5>Must Not:</h5>
                  <li className='hintText'>Match any of the four previous Passwords</li>
                  <li className='hintText' style={{ color: getHintColor(passwordHints.noRepeatingChars) }}>Have more than two repeating characters or numbers in sequence</li>
                  <li className='hintText'>Use your login name, personal information about yourself (Example: first name, last name, email address)</li>
                  <li className='hintText' style={{ color: getHintColor(passwordHints.notStartWithNumber) }}>Begin with number as the first character of the password</li>
                  <li className='hintText' style={{ color: getHintColor(passwordHints.notEndWithNumber) }}>End with number as the last character of the password</li>
                  <li className='hintText' style={{ color: getHintColor(passwordHints.noSequentialChars) }}>Contain characters or numbers in sequence (Example: abc, xyz, 123)</li>
                  <li className='hintText'>Contains keywords such as year (Example: 2020, 1998), season name, month name</li>
                  <li className='hintText' style={{ color: getHintColor(passwordHints.noForbiddenKeywords) }}>Contains keywords such as company or PepsiCo Product name</li>
                </div>
              </div>
              <div >
                <TextInput type="password" value={this} {...register('newPassword', {
                  required: 'Retype Password is required',
                  validate: (value) => value === password || "Passwords do not match"
                })} showLabelIcon required label={t('Retype Password')} />
                {errors.newPassword && <div className='fieldError'>{errors.newPassword.message}</div>}
              </div>
              <div className='endPoints'>
                <div className='accountInfo'>Your password will change for the following systems</div>
                <div className='accountsAlign'>
                  {endpointAccounts.map(point => (
                    <span className='endPointInfo'>{point} </span>
                  ))}
                </div>
              </div>

            </form>
            <div>
              <Checkbox label="" size="small" onUpdate={(event) => { onConfirmation(event) }} text="By Clicking this box I agree to the Terms and Conditions" />
            </div>
            <br /><br />
            <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
              <Button size='medium' onClick={setVerificationMethod} >Previous</Button>
              <Button type="submit" size='medium' className="button" disabled={!isValid || !isConfirmed}>Confirm</Button>
            </div>
          </div>
        </div>
      )}
      {passwordSuccess && (
        <div className="card card-layout reg-card">
          <div className='card-content'>
            <div>
              <h1>Congratulations</h1>
            </div>
            <div className='info-container'>
              <p> You changed your password correctly</p>
            </div>
            <hr className='custom-hr' />
            <div className='password_success_background'>
            </div>
            <div>Your password has been changed for the following systems</div>
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <table className='borderLessTable'>
                <tr style={{ textAlign: 'left' }}>
                  <th>User ID</th>
                  <th>System Name</th>
                </tr>
                <tr>
                  <td className='text-left'>{inputValue}</td>
                  <td className='text-right'>MyIDM</td>
                </tr>
                <tr>
                  <td className='text-left'>{inputValue}</td>
                  <td className='text-right'>IDX</td>
                </tr>
              </table>
            </div>
            <div>
              <Button className='full-button' size="medium" onClick={handleLoginClick}>Login</Button>
            </div>
            <Footer />
          </div>
        </div>
      )}
    </div >
  );
}

export default ForgotPassword;

import axios from "axios";
import Axios from "axios";
import forge from "node-forge";
import CryptoJS from 'crypto-js';
import { tokenStatus } from "../Redux/Actions/fetch_language";

const publicKey = `-----BEGIN RSA PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsoZnlKyNcmMo3zrdx3KGc+/+fk1Jn7zYVyENg8hf6+SQ+p03Mzx8+hO6QUWgkJtlQfWBabZWMolGcpr37QTs4LJc6IEdvy24b8SjWM8AXj6II+SRpOR1m7W9I0eD16+6/nLWBpB5FAxCjrODC//BZVvGStXnTfQZc+/E2EamK21DneFkJaC9FlTwoNSy+LM5bjf6Q2YjWuX06uePzaAOLnycyhA8fHMoHwBQLdzfpKwRKMOr3VnDilem9RH9zGi44rg8y2g2TY1WzvsStmDM8NduV5aOnwzFMo+BTugfLhdP4ZmYR/BX5WLfPmxkAEI5fVNONBCuzoJsyX424wMLlwIDAQAB
    -----END RSA PUBLIC KEY-----`;

export const encrypt = (plainText, key) => {
  const publicKey = forge.pki.publicKeyFromPem(key);
  return forge.util.encode64(
    publicKey.encrypt(plainText, "RSA-OAEP", {
      md: forge.md.md5.create(),
      mgf1: {
        md: forge.md.sha1.create()
      }
    })
  )
};

export const encryptAES = (msg) => {
  const key = CryptoJS.lib.WordArray.random(8).toString();
  const iv = CryptoJS.lib.WordArray.random(8).toString();

  // encrypt some bytes using GCM mode
  const cipher = forge.cipher.createCipher('AES-GCM', key);
  cipher.start({
    iv: iv,
    additionalData: 'nvn', // optional
    tagLength: 128 // optional, defaults to 128 bits
  });
  cipher.update(forge.util.createBuffer(msg));
  cipher.finish();
  const encrypted = cipher.output;
  const encodedB64 = forge.util.encode64(encrypted.data);
  const tag = cipher.mode.tag;
  const tagB64 = forge.util.encode64(tag.data);
  return {
    key: encrypt(key, publicKey),
    iv: iv,
    tag: tagB64,
    encrypt: encodedB64,
  }
};

// Decode JWT to check expiration
const isTokenExpired = (expTime) => {

  try {
    // const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
    return expTime < currentTime; // Token has expired if `exp` is less than current time
  } catch (error) {
    console.error("Error decoding token", error);
    return true; // Consider expired if there’s an error decoding
  }
};

const httpinterceptor = (Store) => {
  const { REACT_APP_API_ENDPOINT, application_key } = process.env;

  const EncrptedData = encryptAES('Pep$!C0MY!DMUn!F!ED@dm!nP0rt@l');
  const data = {
    key: EncrptedData.key,
    tag: EncrptedData.tag,
    iv: EncrptedData.iv,
    encrypt: EncrptedData.encrypt,
  };

  let appAccessToken = localStorage.getItem('application-token');

  // Function to fetch a new token
  async function TokenFetch() {
    try {
      const res = await axios.post(`${REACT_APP_API_ENDPOINT}getToken`, data);
      appAccessToken = res.data.accessToken;
      if (appAccessToken) {
        localStorage.setItem('application-token', appAccessToken);
        // Store.dispatch(tokenStatus(true, appAccessToken));
      } else {
        localStorage.removeItem('application-token');
        // Store.dispatch(tokenStatus(false, ''));
      }
      return appAccessToken; // Return new token
    } catch (error) {
      console.error("Error fetching new token", error);
      return null;
    }
  }

  // Function to handle token validation or fetching a new one
  async function checkAndFetchToken() {
    if (!appAccessToken || isTokenExpired(appAccessToken)) {
      console.log("Token expired or not available, fetching a new one...");
      return await TokenFetch(); // Fetch a new token if expired or not present
    }
    return appAccessToken; // Return existing token if valid
  }

  // Axios request interceptor
  axios.interceptors.request.use(async (config) => {
    // Only check and fetch token for public routes (where the token is required)
    if (window.location.pathname.includes('/public')) {
      if (config.url.includes('getToken')) {
        return config; // Skip if it's the token-fetching request itself
      }

      const token = await checkAndFetchToken(); // Check or fetch token
      config.headers.Authorization = "Bearer " + token; // Attach the token
    } else {
      // Use OKTA token if required for non-public routes
      const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken?.accessToken;
      if (!config.url.endsWith('/health')) {
        config.headers.Authorization = "Bearer " + oktaToken; // Use OKTA token
      }
    }

    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  // Axios response interceptor to handle 401 errors
  axios.interceptors.response.use(
    (response) => {
      return response; // Return the response if no error
    },
    async (error) => {
      const originalConfig = error.config;

      if (error.response && error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true; // Mark the request as retried

        // Fetch a new token

        const newToken = window.location.pathname.includes('/public') ? await TokenFetch() : null;

        if (newToken) {
          // Update the authorization header with the new token
          originalConfig.headers.Authorization = "Bearer " + newToken;
          return axios(originalConfig); // Retry the original request with the new token
        }
      }

      return Promise.reject(error); // Reject other errors
    }
  );
};

//eslint-disable-next-line
export default { httpinterceptor };

import React from 'react';
import { Card, CardContent, Button, Typography, Checkbox,Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const ActionCard = ({ title, content,status,lastupdate, onSelect, selected, classes, delegated, initiated }) => (
  <Card  className={classes.card}>
    <CardContent>
      <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">{title}</Typography>
          <Checkbox className={classes.checkbox}  checked={selected} onChange={onSelect} />
      </Box>      
      <Typography variant="body2" color="text.secondary">
        {content}
      </Typography>
      <Typography variant="h7" component="div">
        {status}
      </Typography>
      <Typography variant="h7" component="div">
        Last update {lastupdate}
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h7">Delegated from     {delegated.lastname}, {delegated.name}</Typography>
        <Typography variant="h6">|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
        <Typography variant="h7">Initiated by     {initiated.lastname}, {initiated.name}</Typography>
      </Box>      

    </CardContent>
  </Card>
);

export default ActionCard;
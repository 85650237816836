import { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";

const ColourPicker = () => {
    const [color, setColor] = useState("#fff");
    useEffect(() => {
        document.body.style.backgroundColor = color;
    }, [color]);
    return <HexColorPicker color={color} onChange={setColor} />;
};

export default ColourPicker;
import React, { useState,useRef  } from 'react';
import { Button, Checkbox, FormControlLabel, Select, MenuItem, Box ,TextField,InputAdornment,IconButton   } from '@mui/material';
import ActionCard from './action-card';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '16px',
    padding: '20px',
  },
  card: {
    padding: '20px',
    color: '#000',
  },
  checkbox: {
    position: 'absolute',
    top: 8,
    right: 8,
    paddingleft: '20px',
  },
  searchBox: {
    marginBottom: 16,
  },
});
const BulkActionCards = () => {
  const [selectedCards, setSelectedCards] = useState([]);
  const [bulkAction, setBulkAction] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const classes = useStyles();

  const handleSelect = (id) => {
    setSelectedCards((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleSelectAll = () => {
    const allItemIds = filteredCards.map(item => item.id);
    setSelectedCards(allItemIds);
   };
  const handleBulkActionChange = (event) => {
    setBulkAction(event.target.value);
    switch (event.target.value) {
      case 'approve':
        console.log('Approved:', selectedCards);
        break;
      case 'reject':
        console.log('Rejected:', selectedCards);
        break;
      case 'reserve':
        console.log('Reserved:', selectedCards);
        break;
      default:
        console.log('No action selected');
    }
  };

  const cards = [
    { id: 1, title: 'ISUM Exception Queue', content: 'Req # 0115987', status:'Overdued', lastupdate: 'Oct 20,2024',classes:classes, delegated: {lastname:'lastname',name:'name'},initiated:{lastname:'lastname',name:'name'} },
    { id: 2, title: 'ISUM Exception Queue', content: 'Req # 0115995', status:'Due in 13 days', lastupdate: 'Oct 31,2024',classes:classes , delegated: {lastname:'lastname',name:'name'},initiated:{lastname:'lastname',name:'name'} },
    { id: 3, title: 'ISUM Exception Queue', content: 'Req # 0116001', status:'Rejected', lastupdate: 'Oct 25,2024',classes:classes, delegated: {lastname:'lastname',name:'name'},initiated:{lastname:'lastname',name:'name'}  },
    { id: 4, title: 'ISUM Exception Queue', content: 'Req # 0116008', status:'In progress' , lastupdate: 'Oct 28,2024',classes:classes, delegated: {lastname:'lastname',name:'name'},initiated:{lastname:'lastname',name:'name'} },
    // Add more cards as needed
  ];

  const filteredCards = cards.filter(card =>
    card.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    card.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
    card.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
    card.lastupdate.toLowerCase().includes(searchQuery.toLowerCase()) ||
    card.delegated.lastname.toLowerCase().includes(searchQuery.toLowerCase()) ||
    card.delegated.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    card.initiated.lastname.toLowerCase().includes(searchQuery.toLowerCase()) ||
    card.initiated.name.toLowerCase().includes(searchQuery.toLowerCase()) 
    );

    const SearchBox = ({ onSearch }) => {
      const classes = useStyles();
      const [searchQuery, setSearchQuery] = useState('');
      const inputRef = useRef(null);

      const handleSearchClick = () => {
        onSearch(searchQuery);
        if (inputRef.current) {
          inputRef.current.focus(); // Keep focus on the input field
        }
      };
    
      return (
        <TextField
          id="search-input"
          className={classes.searchBox}
          label="Search Cards"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearchClick}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            style: { paddingRight: '40px' }
          }}
        />
      );
    };
    

  return (
    <div>
        <div>
            <h1>Pending Approvals</h1>
        </div>
      <div>
      <SearchBox onSearch={setSearchQuery} />
      <Box display="flex" alignItems="center" justifyContent="space-between">
       <h3>Bulk Actions</h3>
       <Box display="flex" alignItems="center" justifyContent="space-between">
          <Button onClick={handleSelectAll} style={{ marginLeft: '8px' }}>
            Select all Items
          </Button>
            <Select value={bulkAction} onChange={handleBulkActionChange} displayEmpty>
              <MenuItem value="" disabled>Select Action</MenuItem>
              <MenuItem value="approve">Approve</MenuItem>
              <MenuItem value="reject">Reject</MenuItem>
              <MenuItem value="reserve">Reserve</MenuItem>
            </Select>
        </Box>
      </Box>
      </div>
        <div className={classes.root}>
        {filteredCards.map((card) => (
          <ActionCard
          key={card.id}
          {...card}
          selected={selectedCards.includes(card.id)}
          onSelect={() => handleSelect(card.id)}
        />
        ))}
      </div>
    </div>
  );
};

export default BulkActionCards;
import { Button, Filter, Icon, Search, Tag } from 'pepsico-ds';
import React from 'react';
import './workflow.css';

const PendingApprovals = () => {
    const cardArray = Array(6).fill(null);
    return (
        <div id="pendingApprovals">
            <span className="page-header">Pending Approvals</span>
            <div>

            </div>
            <div className='filters'>
                <Search
                    onSearch={function Qa() { }}
                    onSelectOption={function Qa() { }}
                    onUpdate={function Qa() { }}
                    placeholderText="Type a search keyword"
                    size="medium" />
                <Filter
                    label="Default"
                    listLabel="Header"
                    onChange={function Qa() { }}
                    options={[
                        {
                            id: '1',
                            label: 'Today'
                        },
                        {
                            id: '2',
                            label: 'Tomorrow'
                        }
                    ]}
                    selection="single"
                    size="small"
                    value={{
                        id: '1',
                        label: 'Today'
                    }}
                />

                <Filter
                    label="Default"
                    listLabel="Header"
                    onChange={function Qa() { }}
                    options={[
                        {
                            id: '1',
                            label: 'This Week'
                        },
                        {
                            id: '2',
                            label: 'Next Week'
                        }
                    ]}
                    selection="single"
                    size="small"
                    value={{
                        id: '1',
                        label: 'This Week'
                    }}
                />
                <Filter
                    label="Default"
                    listLabel="Header"
                    onChange={function Qa() { }}
                    options={[
                        {
                            id: '1',
                            label: 'This Month'
                        },
                        {
                            id: '2',
                            label: 'Next Month'
                        }
                    ]}
                    selection="single"
                    size="small"
                    value={{
                        id: '1',
                        label: 'This Month'
                    }}
                />
                <Filter
                    label="Default"
                    listLabel="Header"
                    onChange={function Qa() { }}
                    options={[
                        {
                            id: '1',
                            label: 'All Time'
                        },
                        {
                            id: '2',
                            label: 'Tomorrow'
                        }
                    ]}
                    selection="single"
                    size="small"
                    value={{
                        id: '1',
                        label: 'All Time'
                    }}
                />
                <span className='link bold-text'>Clear All</span>
                <Icon icon="grid_on" />
                <Icon icon="list_alt" />
            </div>

            <div className='d-flex'>
                <div className='cards-container'>
                    {cardArray.map((card, index) => (
                        <div className='card approvalCard'>
                            <div className='card-content'>
                                <div className='d-flex w-100 justify-content-between mt-2'>
                                    <div className='approvalHeader'>ISUM Exception Queue</div>
                                    <Icon icon="more_vert" />
                                </div>
                                <div className='mt-2'>
                                    <span>Req # 01159421</span>
                                </div>
                                <div className='mt-2'>
                                    <Tag
                                        color="Orange-400"
                                        iconLeading="warning"
                                        onRemove={function Qa() { }}
                                        size="large"
                                        text="Label"
                                        type="filled"
                                    />
                                </div>
                                <div className='d-flex w-100 mt-2' style={{ gap: '12px' }}>
                                    <Icon icon='calendar_today' className='link' />
                                    <span className='detailsValue'>Last Update</span>
                                    <span className='detailsValue'>10/10/2021</span>
                                </div>
                                <div className='d-flex w-100 justify-content-between mt-2'>
                                    <div>
                                        <div className='detailsLabel'>Delegated From</div>
                                        <div className='detailsValue'>John Doe</div>
                                    </div>
                                    <div style={{ border: 'solid 1px #e5e5e5' }}></div>
                                    <div>
                                        <div className='detailsLabel'>Initiated By</div>
                                        <div className='detailsValue'>10/10/2021</div>
                                    </div>
                                </div>
                                <div className='d-flex w-100 justify-content-end mt-4'>
                                    <Button size="small" text="Reject" variant="primaryInverse" iconLeading='close' />
                                    <Button size="small" text="Approve" variant="secondary" iconLeading='check' />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};
export default PendingApprovals;
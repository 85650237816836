import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    home_welcome: "View request pending/approved",
                    language: 'English',
                    view_page_in: "View page in",
                    label_user_id: 'User ID',
                    forgot_password_reset: 'Forgot Password Reset',
                    quick_links: 'Quick Links',
                    password_reset: 'Password Reset',

                },
            },
            zh: {
                translation: {
                    home_welcome: "Bienvenido a la página principal",
                    language: '简体中文',
                    view_page_in: "在中查看页面",
                    label_user_id: '用户 ID - 电子邮件地址或全局人员 ID (GPID)',
                    forgot_password_reset: '忘记密码重置：输入以下内容以识别自己',
                    quick_links: '快速链接',
                    password_reset: '密码重置',
                },
            },
            ru: {
                translation: {
                    home_welcome: "Bienvenido a la página principal",
                    language: 'Русском',
                    view_page_in: "Посмотреть страницу в",
                    label_user_id: '用户 ID - 电子邮件地址或全局人员 ID (GPID)',
                    forgot_password_reset: 'Сброс пароля от забытого: введите следующее, чтобы идентифицировать себя',
                },
            },
            es: {
                translation: {
                    home_welcome: "Bienvenido a la página principal",
                    language: 'Español',
                    view_page_in: "Ver página en",
                    label_user_id: 'ID de usuario: dirección de correo electrónico o ID de persona global (GPID)',
                    forgot_password_reset: 'Restablecimiento de contraseña olvidada: ingrese lo siguiente para identificarse',
                },
            },
            pr: {
                translation: {
                    home_welcome: "Visualizar página em",
                    language: 'Português',
                    view_page_in: "Visualizar página em",
                    label_user_id: 'ID do usuário - endereço de e-mail ou ID de pessoa global (GPID)',
                    forgot_password_reset: 'Forgot Password Reset: Preencha o campo abaixo para identificar-se',
                },
            },
            fr: {
                translation: {
                    home_welcome: "Bienvenue sur la page d'accueil",
                    language: 'Français',
                    view_page_in: "Voir cette page en",
                    label_user_id: 'ID utilisateur - Adresse e-mail ou ID de personne globale (GPID)',
                    forgot_password_reset: 'Réinitialisation du mot de passe oublié : veuillez entrer les informations suivantes pour vous identifier',

                },
            },
        },
        lng: 'en', // Default language
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;

import React, { useState } from 'react';
import { Avatar, Dropdown, DropdownListItem, Header, Icon, List, NotificationMenu, ProfileMenu, Tab, TabsNavigation } from "pepsico-ds";
import { useOktaAuth } from '@okta/okta-react';
// import LanguageDropdown from './languageDropdown';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router";

function HeaderView() {
    const { oktaAuth } = useOktaAuth();
    const { t, i18n } = useTranslation();
    const [langSelected, setLangSelected] = useState('en');
    const [userName, setUserName] = useState('');
    const navigate = useNavigate();
    const data = JSON.parse(localStorage.getItem('okta-token-storage'));

    // Set user name from the token storage
    if (!userName && data?.accessToken?.claims?.testClaim) {
        // console.log(userName)
        // console.log('Data:', data?.accessToken);
        // console.log('Data:', data?.accessToken?.claims);
        // console.log('Data:', data?.accessToken?.claims?.testClaim);
        setUserName(data?.accessToken?.claims?.testClaim);
    }


    const changeLanguage = (lng) => {
        // setLangSelected(lng);
        i18n.changeLanguage(lng);  // Assuming you're using i18next
    };

    const langSelection = (lang) => {
        console.log('Clicked on language:', lang);
        const languageCode = lang === 0 ? 'en' : lang === 1 ? 'es' : lang === 2 ? 'fr' : lang === 3 ? 'pr' : lang === 4 ? 'ru' : lang === 5 ? 'zh' : '';
        localStorage.setItem('lang', languageCode);
        changeLanguage(languageCode);
    };

    const Qa = (lang) => {
        console.log('Clicked on OutSide Lang:', lang);
    };

    const Logout = (index) => {
        if (index == 1) {
            console.log('update_profile')
        }
        else if (index == 2) {
            navigate('/change-password');
        }
        else if (index === 3) {
            localStorage.clear();
            oktaAuth.signOut();
        }
    };

    const onSelection = (val) => {
        if (val == 1) {
            console.log('update_profile')
        } else if (val == 2) {
            navigate('/change-password'); // Redirect to /change-password
        }

    };

    return (
        <div>
            {/* {language &&
                <div style={{ display: 'none' }}>
                    <LanguageDropdown langSelected={language} />
                </div>
            } */}

            <Header
                appLogoProps={null}
                menuIconProps={{}}
                pepsicoFullLogoProps={{
                    className: 'pepsico-logo',
                    colorStyle: 'white',
                    logoType: 'pepsicoFullLogo'
                }}
                rightToolbar={[
                    {
                        dropdownContent: <NotificationMenu bottomButtonProps={{ iconTrailing: 'arrow_forward', onClick: () => Qa(), size: 'small', text: 'See all Notifications', variant: 'borderless' }} onUpdate={function Qa() { }}
                            options={[
                                { details: 'Long text description with max two lines...', footerText: '2 min ago', isDark: false, link2Props: { iconLeading: '', iconTrailing: 'arrow_forward', label: 'View', onClick: () => Qa(), size: 'small', useAnchor: true }, separator: true, showBadge: false, showDotIcon: false, showLink: true, title: 'Approval Pending ', type: 'row' },
                                { details: 'Long text description with max two lines...', footerText: '2 min ago', isDark: false, link2Props: { iconLeading: '', iconTrailing: 'arrow_forward', label: 'View', onClick: () => Qa(), size: 'small', useAnchor: true }, separator: true, showBadge: false, showDotIcon: false, showLink: true, title: 'Associate PAAS Account', type: 'row' }]
                            }
                            popoverProps={{ direction: 'top-right', showPopover: true, text: 'Notifications' }} style={{ width: '290px' }} />,
                        icon: <Icon alt="alt value" className="$color-icon-white" icon="notifications" size="medium" />,
                        onClickIcon: () => Qa()
                    },
                    {
                        dropdownContent: <NotificationMenu
                            onUpdate={langSelection} options={[
                                {
                                    separator: true, className: 'lang-row', showBadge: false, showDotIcon: false, showLink: true, title: 'English',
                                    type: 'row',
                                    onClick: () => langSelection('English'),
                                    defaultValue: 'English'
                                },
                                {
                                    separator: true, className: 'lang-row', showBadge: false, showDotIcon: false, showLink: true, title: 'Español',
                                    type: 'row',
                                    onClick: () => langSelection('Español'),
                                    defaultValue: 'Español'
                                },
                                {
                                    separator: true, className: 'lang-row', showBadge: false, showDotIcon: false, showLink: true, title: 'Français',
                                    type: 'row',
                                    onClick: () => langSelection('Français'),
                                    defaultValue: 'Français'
                                },
                                {
                                    separator: true, className: 'lang-row', showBadge: false, showDotIcon: false, showLink: true, title: 'Português',
                                    type: 'row',
                                    onClick: () => langSelection('Português'),
                                    defaultValue: 'Português'
                                },
                                {
                                    separator: true, className: 'lang-row', showBadge: false, showDotIcon: false, showLink: true, title: 'Русском',
                                    type: 'row',
                                    onClick: () => langSelection('Русском'),
                                    defaultValue: 'Русском'
                                },
                                {
                                    separator: true, className: 'lang-row', showBadge: false, showDotIcon: false, showLink: true, title: '简体中文',
                                    type: 'row',
                                    onClick: () => langSelection('简体中文'),
                                    defaultValue: '简体中文'
                                }
                            ]} popoverProps={{ direction: 'top-right', showPopover: true, text: 'Languages' }} style={{ width: '290px' }} />,
                        icon: <Icon alt="alt value" className="$color-icon-white" icon="language" size="medium" />,

                    },
                    {
                        dropdownContent: (
                            <ProfileMenu
                                onUpdate={Logout}
                                options={[
                                    {
                                        onClick: () => Qa(),
                                        // title: { userName },
                                        "isDark": true,
                                        "badgeProps": {
                                            "color": "Cyan-500", "isRemovable": true, "size": "large", "text": "Label", "type": "filled"
                                        },
                                        "borderlessButtonProps": { "iconTrailing": "chevron_right", "size": "small", "text": "Button", "variant": "borderless" },
                                        "iconProps": { "icon": "account_circle", "size": "medium" },
                                        "leadingIconProps": { "icon": "account_circle", "showIcon": true }, "linkProps": {
                                            "iconLeading": "", "iconTrailing": "chevron_right", "label": "Link", "size": "small", "useAnchor": true
                                        }, "radioButtonProps"
                                            : { "items": ["Radio Label"], "label": "", "reference": "radio button", "size": "large" }, "separator":
                                            false, "showBadge": false, "showDotIcon": false, "showLink": false, "title": userName, "trailingIconProps":
                                            { "icon": "info_outline", "showIcon": true }, "type": "row"

                                    },
                                    {
                                        onClick: (val) => onSelection(val),
                                        title: 'Update Profile',
                                        "isDark": true,
                                        "badgeProps": {
                                            "color": "Cyan-500", "isRemovable": true, "size": "large", "text"
                                                : "Label", "type": "filled"
                                        }, "borderlessButtonProps": {
                                            "iconTrailing": "chevron_right", "size": "small"
                                            , "text": "Button", "variant": "borderless"
                                        }, "iconProps": { "icon": "account_circle", "size": "medium" },
                                        "leadingIconProps": { "icon": "settings", "showIcon": true }, "linkProps": {
                                            "iconLeading": ""
                                            , "iconTrailing": "chevron_right", "label": "Link", "size": "small", "useAnchor": true
                                        }, "radioButtonProps"
                                            : { "items": ["Radio Label"], "label": "", "reference": "radio button", "size": "large" }, "separator":
                                            false, "showBadge": false, "showDotIcon": false, "showLink": false, "trailingIconProps":
                                            { "icon": "info_outline", "showIcon": true }, "type": "row", "text": "Button",
                                        "variant": "borderless"
                                    },
                                    {
                                        onClick: () => onSelection('change_password'),
                                        title: 'Change Password',
                                        "isDark": true,
                                        "badgeProps": {
                                            "color": "Cyan-500", "isRemovable": true, "size": "large", "text"
                                                : "Label", "type": "filled"
                                        }, "borderlessButtonProps": {
                                            "iconTrailing": "chevron_right", "size": "small"
                                            , "text": "Button", "variant": "borderless"
                                        }, "iconProps": { "icon": "account_circle", "size": "medium" },
                                        "leadingIconProps": { "icon": "settings", "showIcon": true }, "linkProps": {
                                            "iconLeading": ""
                                            , "iconTrailing": "chevron_right", "label": "Link", "size": "small", "useAnchor": true
                                        }, "radioButtonProps"
                                            : { "items": ["Radio Label"], "label": "", "reference": "radio button", "size": "large" }, "separator":
                                            false, "showBadge": false, "showDotIcon": false, "showLink": false, "trailingIconProps":
                                            { "icon": "info_outline", "showIcon": true }, "type": "row", "text": "Button",
                                        "variant": "borderless"
                                    },
                                    {
                                        onClick: () => Logout(),
                                        title: 'Logout',
                                        "isDark": true,
                                        "badgeProps": {
                                            "color": "Cyan-500", "isRemovable": true, "size": "large", "text"
                                                : "Label", "type": "filled"
                                        }, "borderlessButtonProps": {
                                            "iconTrailing": "chevron_right", "size": "small"
                                            , "text": "Button", "variant": "borderless"
                                        }, "iconProps": { "icon": "account_circle", "size": "medium" },
                                        "leadingIconProps": { "icon": "settings", "showIcon": true }, "linkProps": {
                                            "iconLeading": ""
                                            , "iconTrailing": "chevron_right", "label": "Link", "size": "small", "useAnchor": true
                                        }, "radioButtonProps"
                                            : { "items": ["Radio Label"], "label": "", "reference": "radio button", "size": "large" }, "separator":
                                            false, "showBadge": false, "showDotIcon": false, "showLink": false, "trailingIconProps":
                                            { "icon": "info_outline", "showIcon": true }, "type": "row", "text": "Button",
                                        "variant": "borderless"
                                    }
                                ]}
                                style={{ width: '330px' }}
                            />
                        ),
                        icon: (
                            <>
                                <Avatar imageUrl="" name={userName ? userName : 'User'} size="large" />
                                <Icon alt="Profile" icon="arrow_drop_down" size="medium" />
                            </>
                        ),

                    },

                ]}
            />
        </div >
    );
}

export default HeaderView;

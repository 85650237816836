// MenuContext.js
import React, { createContext, useContext, useState } from 'react';

export const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
    const [menuLayout, setMenuLayout] = useState('left'); // default to "left" menu

    const toggleMenuLayout = (layout) => {
        setMenuLayout(layout);
    };

    return (
        <MenuContext.Provider value={{ menuLayout, toggleMenuLayout }}>
            {children}
        </MenuContext.Provider>
    );
};

export const useMenuContext = () => useContext(MenuContext);


export const useMenu = () => {
    const context = useContext(MenuContext);
    if (!context) {
      throw new Error('useMenu must be used within a MenuProvider');
    }
    return context;
  };
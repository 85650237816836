// FontSizeContext.js
import React, { createContext, useState, useContext } from 'react';

const FontSizeContext = createContext();

export const useFontSize = () => useContext(FontSizeContext);

export const FontSizeProvider = ({ children }) => {
    const [fontSize, setFontSize] = useState('medium');
    const [theme, setTheme] = useState('light');

    return (
        <FontSizeContext.Provider value={{ fontSize, setFontSize, theme, setTheme }}>
            {children}
        </FontSizeContext.Provider>
    );
};

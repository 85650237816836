import React from "react";

import { useOktaAuth } from "@okta/okta-react";

const LoginCallBack = ()=>{

    const { authState,oktaAuth } = useOktaAuth();
    React.useEffect(() => {
        const fetchAccessToken = async () => {
          try {
            const accessToken = await oktaAuth.tokenManager.get('accessToken');
            // console.log('Access Token:', accessToken?.accessToken);
          } catch (err) {
            console.error('Error getting access token', err);
          }
        };
        // console.log('test');
        // oktaAuth.token.getWithRedirect().then(tokens => {
        //     console.log(tokens);
        //     // Store the access token
        //     oktaAuth.tokenManager.set('accessToken', tokens.accessToken);
        // }).catch(err => console.error(err));
        

    
        fetchAccessToken();
      }, [oktaAuth]);
    // React.useEffect(()=>{
    //     oktaAuth.getAccessToken();
    //     // console.log({authState})
    //     // console.log(oktaAuth)
    // },[]);

    return (
        <>
        <div>
            {/* dsadasd */}
            </div>
        </>
    )

}

export default LoginCallBack;
import React, { useEffect, useState } from 'react';
import { TextInput, Button, Link } from 'pepsico-ds';
import { useTranslation } from 'react-i18next';
import './auth.css';
import { useForm } from 'react-hook-form';
import ForgotPassword from './forgot-password';
import Registration from './registration';
import { useNavigate } from 'react-router-dom';
import Footer from '../common/footer';
import { useOktaAuth } from "@okta/okta-react"

function Login() {
    console.log('tokens');
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState('');
    const [gpidErrorMessage, setGpidErrorMessage] = useState('');
    const [password, setPassword] = useState('');
    const handleChange = (event) => {
        const onlyNumbers = event.target.value.replace(/[^0-9]/g, ''); // Regex to remove non-numeric characters
        setInputValue(onlyNumbers);
        // setValidId(true);
        if (event.target.value?.length === 8) {
            // apitestGet();
            // getAuthToken();

        }
    }
    const { oktaAuth } = useOktaAuth()
    const navigate = useNavigate();
    useEffect(() => {
        const checkAuthenticationAndRedirect = async () => {
            const isAuthenticated = await oktaAuth.isAuthenticated();
            if (isAuthenticated) {
                // If the user is already authenticated, redirect to the home page or another protected route
                navigate('/home');
            } else {
                // If not authenticated, trigger sign-in with Okta
                await oktaAuth.signInWithRedirect();
            }
        };
        checkAuthenticationAndRedirect();
    }, [oktaAuth, navigate]);

    // const onPasswordChange = (event) => {
    //     setPassword(event.target.value);
    // }

    // const onForgotPassword = () => {
    //     navigate('/public/forgot-password');

    // }
    // const onRegistration = () => {
    //     navigate('/public/registration');
    // }
    

    // oktaAuth.isAuthenticated().then(data => {
    //     console.log('isAuthenticated', data);
    //     // comment below for local development
    //     if (!window.location.origin.includes('localhost')) {
    //         console.log(window.location.origin);

    //         if (data) {
    //             oktaAuth.token.getWithRedirect().then(tokens => {
    //                 console.log(tokens);
    //                 // Store the access token
    //                 oktaAuth.tokenManager.set('accessToken', tokens.accessToken);
    //             }).catch(err => console.error(err));
    //             oktaAuth.signInWithRedirect()
    //         }
    //     }
    // });
    // const loginEvent = () => {
    //     oktaAuth.token.getWithRedirect().then(tokens => {
    //         console.log(tokens);
    //         // Store the access token
    //         oktaAuth.tokenManager.set('accessToken', tokens.accessToken);
    //     }).catch(err => console.error(err));
    //     oktaAuth.signInWithRedirect()
    //     if (inputValue.length === 8 && inputValue === '09443473' && password) {
    //         navigate('/home');
    //     }
    //     else {
    //     }
    // };

    // return (
    //     <div>
    //         <div className="card card-layout">
    //             <div className='card-content'>
    //                 <div className='idm_logo_background'></div>
    //                 <div>
    //                     <h2>Welcome to myidM</h2>

    //                 </div>

    //                 <hr className='custom-hr' />

    //                 <div className='w-1-2'>
    //                     {/* <div>
    //                         <TextInput type="text" required showLabelIcon label={t('GPID')} value={inputValue} onChange={handleChange} />
    //                         {gpidErrorMessage && <span className='error'>{gpidErrorMessage}</span>}
    //                     </div>
    //                     <div>
    //                         <TextInput type="password" required showLabelIcon label={t('Password')} value={password} onChange={onPasswordChange} />
    //                     </div> */}

    //                     <div style={{ marginTop: '24px' }}>
    //                         <Button className='full-button' size="medium" text='Login' onClick={loginEvent} />
    //                         <br />
    //                         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    //                             <span className='link bold-text' onClick={onForgotPassword}>Forgot Password</span>
    //                             <span className='link bold-text' onClick={onRegistration}>First Time Registration</span>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 <Footer />
    //             </div>
    //         </div>
    //         {/* <div  >
    //             {!isRegistration && !forgotPassword && (
    //                 <div className="card card-layout">
    //                     <div className='card-content'>
    //                         <div className='idm_logo_background'></div>
    //                         <div>
    //                     <h2>Welcome to myidM</h2>

    //                         </div>
                            
    //                         <hr className='custom-hr' />

    //                         <div style={{ display: 'inline-grid' }}>
    //                             <TextInput type="text" required showLabelIcon label={t('GPID')} value={inputValue} onChange={handleChange} />
    //                             {gpidErrorMessage && <span className='error'>{gpidErrorMessage}</span>}
    //                             <br />

    //                             <div style={{ marginTop: '24px' }}>
    //                                 <Button className='full-button' size="medium" text='Sign In' onClick={loginEvent} />
    //                                 <br />
    //                                 <span className='link bold-text' onClick={onForgotPassword}>Forgot Password</span>
    //                                 <br />
    //                                 <br />
    //                                 <span className='link bold-text' onClick={onRegistration}>First Time Registration</span>
    //                             </div>
    //                         </div>
    //                        <Footer/>
    //                     </div>
    //                 </div>
    //             )}
    //             {forgotPassword && (
    //                 <ForgotPassword />
    //             )}
    //             {isRegistration && (
    //                 <Registration />
    //             )}
    //         </div> */}
    //     </div>
    // )
}

export default Login;